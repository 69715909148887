import { StaticQuery, graphql } from "gatsby";
import React from "react";
import Section from "../Section";
import SectionTitle from "../SectionTitle";
import TextImageSection from "../TextImageSection";

const About = () => (
  <Section className="space-y-8 lg:space-y-8">
    <SectionTitle
      subtitle="Der Wettbewerb"
      title="Roboterwettbewerb als Bildungsmaßnahme"
      desc="Zukunftstechnologien schon heute praktisch zu vermitteln - das ist unsere Mission für compAIR. Durch die Kombination von Spitzentechnologien, interaktiven und modernen pädagogischen Ansätzen und dem Nervenkitzel eines internationalen Robotikwettbewerbs gewinnen nicht nur die Besten, sondern die Teilnahme ist ein Wissensgewinn für alle."
    />
    <StaticQuery
      query={graphql`
        {
          mentoring: file(relativePath: { glob: "**/mentoring.jpg" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          airlab: file(relativePath: { glob: "**/airlab.jpeg" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
          robo4you: file(relativePath: { glob: "**/robo4you.jpg" }) {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      `}
      render={(data) => (
        <>
          <TextImageSection
            title="Code Review"
            image={data.mentoring.childImageSharp.gatsbyImageData}
            alternate
          >
            <p>
              Als Teil des Wettbewerbs wird ein Code-Review durchgeführt. Hier
              erhalten die Teams ein unmittelbares Feedback zu ihren
              Programmierfähigkeiten.
            </p>
          </TextImageSection>
          <TextImageSection
            title="Makerspace AIRlab"
            image={data.airlab.childImageSharp.gatsbyImageData}
          >
            <p>
              Das Finale der compAIR findet im AIRlab statt. Dieses einzigartige
              Robotiklabor an der HTBLuVA Wiener Neustadt bietet mit seiner
              Ausstattung den idealen Ort für innovative Robotik. compAIR
              TeilnehmerInnen können das AIRlab nutzen, um ihre Roboter zu
              entwickeln und zu testen.
            </p>
          </TextImageSection>
          <TextImageSection
            title="Entwickelt & oragnisiert vom robo4you"
            image={data.robo4you.childImageSharp.gatsbyImageData}
            alternate
          >
            <p>
              compAIR wurde vom robo4you entwickelt und wird durch freiwillige
              ausgetragen. robo4you kann auf mehr als 15 Jahre Erfahrung in der
              Robotikausbildung zurückgreifen. Von uns unterstützte Teams
              konnten 11 Weltmeistertitel, 20 Europameistertitel und zahlreiche
              Sonderpreise in den letzten Jahren erringen.
            </p>
          </TextImageSection>
        </>
      )}
    />
  </Section>
);

export default About;
