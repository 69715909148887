import React from "react";

const Button = ({ title, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="cursor-pointer inline bg-primary text-white font-semibold hover:shadow-lg transition-shadow px-4 py-2 rounded-lg"
  >
    {title}
  </a>
);

export default Button;
