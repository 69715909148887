import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Section from "../Section";
import SectionTitle from "../SectionTitle";

const facts = [
  {
    number: "5.000",
    text: "Live-Stream Zuschauer",
  },
  {
    number: "125",
    text: "Teilnehmer",
  },
  {
    number: "30",
    text: "Teams",
  },
  {
    number: "30",
    text: "Freiwillige und Mentoren",
  },
];

const Advantage = () => (
  <Section>
    <SectionTitle
      title="Die wichtigsten Fakten"
      desc="compAIR wird live gestreamt - Interessierte weltweit können virtuell an diesem einzigartigen Robotik-Event teilnehmen. Die letzten zwei Wettbewerbe, lassen sich mit folgenden Zahlen bereits eindrucksvoll beschreiben:"
    />
    <div className="grid lg:grid-cols-2 gap-8">
      <div>
        <div className="grid grid-cols-2 gap-8">
          {facts.map((fact) => (
            <div key={fact.title}>
              <div className="text-primary font-bold text-xl border-l-2 border-primary pl-4 -ml-4">
                {fact.number}
              </div>
              <div className="text-semibold text-gray-700 mt-">{fact.text}</div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <StaticImage
          src="../../../content/images/robo4you.jpeg"
          alt="compAIR Advantage"
          className="rounded"
        />
      </div>
    </div>
  </Section>
);

export default Advantage;
