import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Intro from "../components/Intro";
import Seo from "../components/Seo";
import Scenario from "../components/Scenario";
import About from "../components/About";
import Stem from "../components/Stem";
import Sponsors from "../components/Sponsors";
import SignUp from "../components/SignUp";
import Advantage from "../components/Advantage";
import VideoSeeding from "../components/VideoSeeding";
import DoubleElimVideo from "../components/DoubleElimVideo";

export default function IndexPage({ data, location }) {
  return (
    <Layout>
      <Seo url={location.pathname} />
      <Intro />

      {/* <Livestream/> */}

      {/* <Schedule/> */}

      <Scenario />

      <VideoSeeding />
      <DoubleElimVideo />

      <SignUp />

      {/* <Teaser/> */}

      <Stem />

      <About />
      <Advantage />

      <Sponsors />
    </Layout>
  );
}

export const query = graphql`
  query IndexPageQuery {
    statement1: file(relativePath: { glob: "**/start_team.jpg" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;
