import React from "react";
import Section from "../Section";
import SectionTitle from "../SectionTitle";
import Button from "../Button";

const steps = [
    {
        title: "Informationsveranstaltung",
        date: "13. September 2024, 14:30",
        text: "Präsentation und Überblick über den Wettbewerb.\nOrt: Seminarraum des AIRlab an der HTL Wr. Neustadt"
    },
    {
        title: "Saisonbeginn",
        date: "13. September 2024",
        text: "In jeder Saison werden ein neues Szenario und neue Herausforderungen entwickelt. Die planmäßige Veröffentlichung dieses Dokuments ermöglicht es jedem Team, die gleiche Vorbereitungszeit in Anspruch zu nehmen.",
    },
    {
        title: "Anmeldeschluss",
        date: "22. September 2024",
        text: "",
    },
    /*{
        title: "Coding Challenge 1",
        date: "4. Dezember 2022",
        text: "Die erste Programmieraufgabe muss bis zu diesem Datum hochgeladen werden.",
    },
    {
      title: "Coding Challenge 2",
      date: "5. Februar 2023",
      text: "Die zweite Programmieraufgabe muss bis zu diesem Datum hochgeladen werden.",
    },
    {
      title: "Seeding - 1. Tag",
      date: "22. Februar 2023",
      text: "An diesem Tag wird die erste Hälfte der Disziplin 'Seeding' ausgetragen.",
    },*/
    {
        title: "Seeding",
        date: "29. November 2024 von 13 bis 18 Uhr",
        text: "An diesem Tag wird der 'Seeding' Teil des Wettbewerbs ausgetragen. Der Wettbewerb wird vor Ort im AIRlab der HTL Wr. Neustadt stattfinden.",
    },
    {
        title: "Großes Finale: Double Elimination",
        date: "30. November 2024 von 10 bis 15 Uhr",
        text: "Am zweiten Tag des Wettbewerbs finden die Double Elimination und das große Finale statt. Verpassen Sie dieses großartige Ereignis nicht!",
    },
];

const SignUp = () => (
    <Section id="sign-up">
        <div className="grid lg:grid-cols-2 gap-8">
            <div>
                <SectionTitle
                    title="Bist du bereit loszulegen?"
                    subtitle="Der Zeitplan"
                    desc="Die Anmeldung für die zweite Saison wurde Anfang August eröffnet und ist mittlerweile vorbei. Die Anmeldung für die dritte Saison wird voraussichtlich wieder im August eröffnet. Um nächstes Mal teilzunehmen, müssen die folgenden Anforderungen erfüllt werden:"
                />
                <ul>
                    <li>Altersgrenze: 19 Jahre</li>
                    <li>Teamgröße: 4-6 Mitglieder</li>
                </ul>
                {<div className="mt-12">
                    <Button title="Sign up now ->" href="https://upload.comp-air.at" />
                </div>}
            </div>
            <div>
                {steps.map((step, index) => {
                    const border =
                        index + 1 < steps.length ? "border-l-2 border-dashed" : "";

                    return (
                        <div
                            data-sal="slide-up"
                            data-sal-delay={index * 100}
                            data-sal-easing="ease"
                        >
                            <div>
                                <div
                                    className="h-8 w-8 inline-flex items-center justify-center bg-primary p-2 rounded-full text-white font-semibold">
                                    {index + 1}
                                </div>
                                <h2 className="inline pl-4 font-bold text-gray-800">
                                    {step.title}
                                </h2>
                            </div>
                            <p className={`ml-4 pl-8 text-gray-700 ${border}`}>{step.date}</p>
                            <div
                                className={`ml-4 pl-8 pt-4 pb-8 text-gray-700 prose ${border}`}
                            >
                                <p>{step.text.split('\n').map(s => <>{s}<br/></>)}</p>
                            </div>
                            {step.link ? <div
                                className={`ml-4 pl-8 pb-8 text-gray-700 prose ${border}`}
                            >
                                Verfügbar unter: <a href={step.link}>{step.link}</a>
                            </div> : <></>}
                        </div>
                    );
                })}
            </div>
        </div>
    </Section>
);

export default SignUp;
