import React from "react";

import Section from "../Section";
import SectionTitle from "../SectionTitle";

const sections = [
  {
    title: "Seeding",
    content:
      "In dieser Disziplin sind die Teams auf sich allein gestellt. Die Aufgabe lautet: Innerhalb von zwei Minuten so viele Punkte wie möglich zu sammeln. Autonome Entscheidungsfindung, Reproduzierbarkeit und Konsistenz sind von entscheidender Bedeutung. Jede Strategie muss ausgewogen und gut durchdacht sein.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-9 w-9"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
        />
      </svg>
    ),
  },
  {
    title: "Coding Challenges",
    content:
      "Im Vorfeld der Veranstaltung müssen die Teams zwei Programmieraufgaben lösen. Für jede Aufgabe werden von der Wettbewerbsjury Punkte vergeben. Außerdem erhalten die Teams für jede Einsendung ein Feedback, das den Teilnehmern hilft, ihre Programmierkenntnisse zu verbessern.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-9 w-9"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
        />
      </svg>
    ),
  },
  {
    title: "Double Elimination",
    content:
      "In dieser Disziplin kämpfen die Teams in einem Kopf-an-Kopf-Turnier mit doppelter Eliminierung. Zwei Roboter starten auf dem Feld, aber nur einer wird siegreich sein. Überliste den Gegner, sammle Power-Ups und kämpfe um mehr Punkte, bis die Zeit abgelaufen ist. Nervenkitzel bis zum Ende.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-9 w-9"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    ),
  },
];

const Scenario = () => (
  <div>
    <Section background>
      <SectionTitle
        subtitle="Ein Überblick"
        title="Die Herausforderung der Saison: Autonome Landwirtschaft"
        desc="In dieser Saison stellen sich die Teams der Herausforderung der autonomen Landwirtschaft. Die Roboter müssen virtuelle Heuballen aufsammeln, Container Sortieren und Güter über das Spielfeld transportieren."
      />
      <div className="grid lg:grid-cols-2 gap-4 lg:gap-8">
        {sections.map((section, index) => (
          <div
            key={section.title}
            data-sal="slide-up"
            data-sal-delay={index * 100}
            data-sal-easing="ease"
          >
            <div className="mb-2 text-primary">{section.icon}</div>
            <h1 className="border-l-2 -ml-4 pl-4 border-primary text-xl font-bold">
              {section.title}
            </h1>
            <p className="prose text-gray-600 font-semibold mt-2">
              {section.content}
            </p>
          </div>
        ))}
      </div>
    </Section>
  </div>
);

export default Scenario;
