import React from "react";
import Section from "../Section";

const features = [
  {
    title: "Mathematik",
    content:
      "Mathematik ist wie ein Puzzle, man muss logisch denken und ein Problem Schritt für Schritt, Stück für Stück lösen.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-9 w-9"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
        />
      </svg>
    ),
  },
  {
    title: "Informatik",
    content:
      "Die moderne Technik stützt sich mehr denn je auf Software, und autonome Roboter werden immer ausgereifter. Insbesondere die Robotik ist ein sehr interdisziplinäres Gebiet, das Fähigkeiten aus vielen Fachbereichen erfordert.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-9 w-9"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
        />
      </svg>
    ),
  },
  {
    title: "Naturwissenschaften",
    content:
      "Lasersensoren, IMUs und Ähnliches beruhen auf physikalischen Prinzipien - das richtige Werkzeug für die richtige Aufgabe zu verwenden, ist eine wichtige Fähigkeit fürs Leben.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-9 w-9"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
        />
      </svg>
    ),
  },
  {
    title: "Technik",
    content:
      "Die Entwicklung von Software ist nur ein Teil des Geschäfts, ohne Roboter, auf denen sie läuft, wäre dies sinnlos. Von der Elektrotechnik über den Maschinenbau bis zur Leiterplattenentwicklung.",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-9 w-9"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 10V3L4 14h7v7l9-11h-7z"
        />
      </svg>
    ),
  },
];

const Stem = () => (
  <div>
    <Section>
      <div className="grid md:grid-cols-4 gap-4">
        {features.map((feature, index) => (
          <div key={feature.title}>
            <div className="mb-2 text-primary">{feature.icon}</div>
            <h1 className="border-l-2 -ml-4 pl-4 border-primary text-xl font-bold">
              {feature.title}
            </h1>
            <p className="prose text-gray-800 mt-2">{feature.content}</p>
          </div>
        ))}
      </div>
    </Section>
  </div>
);

export default Stem;
