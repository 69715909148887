import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const Left = ({ title, children }) => (
  <div>
    <h1 className="text-2xl text-gray-800 font-bold">{title}</h1>
    {children && (
      <div className="text-gray-600 prose font-semibold mt-6">{children}</div>
    )}
  </div>
);

const Right = ({ image, title }) => (
  <div>
    <GatsbyImage
      image={image}
      className="lg:rounded shadow"
      imgClassName="rounded"
      alt={title}
    />
  </div>
);

const TextImageSection = ({ title, children, image, alternate }) => (
  <div className="grid lg:grid-cols-2 gap-4 lg:gap-8">
    {alternate ? (
      <>
        <Left title={title} children={children} />
        <Right title={title} image={image} />
      </>
    ) : (
      <>
        <Right title={title} image={image} />
        <Left title={title} children={children} />
      </>
    )}
  </div>
);

export default TextImageSection;
