import React from "react";
import Section from "../Section";

const DoubleElimVideo = () => (
  <Section background backgroundClassName="bg-gray-200">
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div>
        <div className="prose prose-lg">
          <h1 className="font-bold">
            Teil 2:{" "}
            <span className="text-gradient bg-gradient-to-r from-amber-500 to-amber-600">
              Double Elimination
            </span>
          </h1>
          <p>
            <b>
              Im "Double Elimination" Teil des Wettbewerbs geht es ums Finale.
            </b>
          </p>
          <p>
            Im zweiten Teil des Wettbewerbs treten die Teams im "Double
            Elimination" Turnier des Wettbewerbs gegeneinander an. Zwei Roboter
            starten auf dem Spielfeld, aber nur einer kann gewinnen. Überliste
            den Gegner, sammle Power-Ups und kämpfe um mehr Punkte, bis die Zeit
            abgelaufen ist. Dieser Teil des Wettbewerbs verspricht Nervenkitzel
            bis zum Ende.
          </p>
        </div>
      </div>

      <div>
        <style>
          {
            ".embed-container { position: relative; margin: 2em;padding-bottom: 75%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
          }
        </style>

        <div className="embed-container">
          <iframe
            title="Livestream 19th February - Tag 2 - Double Elimination"
            src="https://www.youtube.com/embed/Mfew9QmDAE8"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  </Section>
);

export default DoubleElimVideo;
