import React from "react";
import Section from "../Section";

const compAIRSeedingVideo = () => (
  <Section background backgroundClassName="bg-gray-200">
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div>
        <style>
          {
            ".embed-container { position: relative; margin: 2em;padding-bottom: 75%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
          }
        </style>

        <div className="embed-container">
          <iframe
            title="Livestream 18ter Februar - Tag 1 - Seeding"
            src="https://www.youtube.com/embed/thNvqfXhwAo?start=12"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div>
        <div className="prose prose-lg">
          <h1 className="font-bold">
            Teil 1:{" "}
            <span className="text-gradient bg-gradient-to-r from-amber-500 to-amber-600">
              Seeding
            </span>
          </h1>
          <p>
            <b>
              Im "Seeding" Teil des Wettbewerbs geht es darum zu zeigen, was der
              eigene Roboter alles kann.
            </b>
          </p>
          <p>
            Im ersten Teil des Wettbewerbs treten die Teams in der Einzelwertung
            "Seeding" an. Die Aufgabe lautet: Innerhalb von zwei Minuten so
            viele Punkte wie möglich zu sammeln. Autonome Entscheidungsfindung,
            Reproduzierbarkeit und Konsistenz sind von entscheidender Bedeutung.
            Jede Strategie muss ausgewogen und gut durchdacht sein.
          </p>
        </div>
      </div>
    </div>
  </Section>
);

export default compAIRSeedingVideo;
