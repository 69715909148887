import React from "react";

export default function SectionTitle({ title, subtitle, desc }) {
  return (
    <div className="mb-6">
      {subtitle && (
        <h3 className="text-primary text-lg font-bold mb-4 lg:mb-8">
          {subtitle}
        </h3>
      )}
      <h1 className="text-3xl lg:text-4xl text-gray-800 font-bold">{title}</h1>
      {desc && (
        <div className="text-gray-600 prose font-semibold mt-6">
          <p>{desc}</p>
        </div>
      )}
    </div>
  );
}
