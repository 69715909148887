import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const IntroImage = ({ image, title }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 lg:mr-48 pb-12 bg-gradient-to-b lg:bg-gradient-to-r from-secondary-light to-secondary" />
      <div className="px-8 lg:px-0 pb-8 lg:pb-12">
        <GatsbyImage
          image={image}
          className="max-h-full-no-nav lg:rounded-l shadow-2xl"
          loading="eager"
          alt={title}
        />
      </div>
    </div>
  );
};

const Hero = ({ image, title, desc, cta }) => (
  <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 max-w-screen-4k mx-auto text-white bg-background-light">
    <div className="bg-secondary-light lg:mt-12 flex justify-center items-center flex-col">
      <div className="lg:w-2/3 py-8 px-8">
        <h1 className="font-black text-5xl subpixel-antialiased leading-normal text-gray-700">
          {title}
        </h1>
        <div className="mt-4 max-w-lg text-xl font-medium text-gray-600 prose">
          {desc}
        </div>
        {cta && (
          <div className="mt-10 text-white">
            <a
              href="https://upload.comp-air.at"
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 font-semibold bg-gradient-to-r from-primary to-primary-dark hover:shadow-lg transition rounded"
            >
              {cta}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                width="20px"
                height="20px"
                className="h-5 w-5 inline ml-2"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        )}
      </div>
    </div>
    <div>
      <IntroImage image={image} title={title} />
    </div>
  </div>
);

export default Hero;
